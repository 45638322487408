import React, { useEffect } from "react";

import { SourceIds, LayerStyles, LayerIds } from "./constants";
import { TileCacheRefresher } from "./refresh-tiles";

const tilesResresher = new TileCacheRefresher(SourceIds.PROCESSED);
function ProcessingResultsLayer({ mapAPI, vectorLayer, completedArea }) {
  const tileJsonUrl = vectorLayer?.tileJsonUrl;
  useEffect(() => {
    if (!tileJsonUrl) {
      return;
    }
    if (completedArea === 0) {
      return;
    }

    if (mapAPI.getSource(SourceIds.PROCESSED)) {
      return;
    }
    addVectorLayer(mapAPI, tileJsonUrl);
  }, [tileJsonUrl, mapAPI, completedArea]);

  useEffect(() => {
    if (completedArea === 0) return;
    tilesResresher.hardRefresh(mapAPI);
  }, [mapAPI, completedArea]);

  return null;
}

function addVectorLayer(mapAPI, url) {
  const sourceId = SourceIds.PROCESSED;
  const source = { type: "vector", url };
  const fillId = LayerIds.PROCESSED;
  const fill = LayerStyles.getProcessed(fillId, sourceId);
  const outlineId = LayerIds.PROCESSED_OUTLINE;
  const outline = LayerStyles.getProcessedOutline(outlineId, sourceId);
  const symbolId = LayerIds.PROCESSED_POINTS;
  const symbol = LayerStyles.getProcessedSympols(symbolId, source);
  const line = LayerStyles.getProcessedLines(outlineId, sourceId);

  mapAPI.addSource(sourceId, source);

  mapAPI.addLayer(fill).addLayer(outline).addLayer(line).addLayer(symbol);

  // mapAPI.loadImage(
  //   "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png", // Replace with your correct icon URL
  //   (error, image) => {
  //     if (error) {
  //       console.error("Error loading icon image:", error);
  //       // Handle the error gracefully (e.g., display a default icon or message)
  //       // You can use a placeholder icon or display a text message indicating the error
  //       const defaultIconUrl =
  //         "https://placehold.it/30x30.png?text=Icon+Loading"; // Example placeholder
  //       mapAPI.loadImage(defaultIconUrl).then((defaultImage) => {
  //         // Use fetch for default icon if needed
  //         mapAPI.addImage("default-icon", defaultImage);
  //         addPointLayer(mapAPI, "default-icon"); // Add point layer using default icon
  //       });
  //     } else {
  //       // Add the image to the map style
  //       mapAPI.addImage("icon", image);
  //       addPointLayer(mapAPI, "icon"); // Add point layer using your icon
  //     }
  //   },
  // );

  // function addPointLayer(mapAPI, iconId) {
  //   mapAPI.addLayer({
  //     id: "point",
  //     type: "symbol",
  //     source,
  //     "source-layer": "vector_layer", // Replace with the point layer name in your vector tiles
  //     layout: {
  //       "icon-image": iconId, // Use "default-icon" or "your-icon-id" based on error handling
  //       "icon-size": 0.5,
  //       "icon-allow-overlap": true,
  //     },
  //     paint: {},
  //   });
  // }
}

export default React.memo(ProcessingResultsLayer);
