import ky from "ky";

import { BACKEND_URL } from "constants/envs";
import { clientCookies } from "shared/keycloak";

/**
 *
 * @param {Request} request
 */
function attachBearerToken(request) {
  const { token } = clientCookies.getTokens();
  if (token) {
    request.headers.set("authorization", `Bearer ${token}`);
  }
}

export const client = ky.extend({
  prefixUrl: BACKEND_URL + "/rest",
  hooks: {
    beforeRequest: [attachBearerToken],
  },
});
