import React from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { gql, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { StateLoading, EmptyMessage, ErrorMessage } from "components";

import { AoiListItem } from "./aoi-list-item";
import { AoiListHeader } from "containers";
import { Button, Intent } from "@blueprintjs/core";
import { POLL_INTERVAL } from "constants/envs";

export const GET_AOIS = gql`
  query getAois($processingId: ID!, $sort: [AoiSortEntry!]) {
    aois(sort: $sort, filter: { processingIds: [$processingId] }) {
      aois {
        id
        area
        progress {
          status
          percentCompleted
        }
        messages {
          message
        }
      }
    }
  }
`;

const getAoisList = (client) => async (data) => {
  const result = await client.query({
    query: GET_AOIS,
    variables: data,
    fetchPolicy: "no-cache",
  });
  return result?.data?.aois?.aois;
};

function AoiList({ onUpload ,isGeotiffDataProvider}) {
  const { processingId } = useParams();

  const client = useApolloClient();
  const queryClient = useQueryClient();

  const sortQuery = useQuery({
    queryKey: ["aoiListSort"],
    queryFn: () => [],
  });

  const sort = sortQuery.data;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["processing", processingId, "aois", sort],
    queryFn: () => getAoisList(client)({ processingId, sort }),
    refetchInterval: POLL_INTERVAL,
    onSuccess: (data) => {
      data.forEach((aoi) => {
        queryClient.setQueryData(["aoi", aoi.id], aoi);
      });
    },
  });
  const isEmpty = !(data && data.length > 0);

  const loadingMessage = (
    <StateLoading
      className="aoi-list-loader"
      title={<Trans>Loading</Trans>}
      description={<Trans>Fetching AOI's</Trans>}
    />
  );

  const errorMessage = (
    <ErrorMessage
      title={<Trans>Unable to load AOIs</Trans>}
      description={error}
    />
  );

  const emptyMessage = (
    <EmptyMessage
      title={<Trans>You haven’t created any AOI yet</Trans>}
      action={
        <Button
          outlined
          intent={Intent.SUCCESS}
          text={<Trans>Upload Areas of interest</Trans>}
          onClick={onUpload}
        />
      }
    />
  );

  return (
    <div className="aoi-list">
      <AoiListHeader />
      {isLoading ? (
        loadingMessage
      ) : isError ? (
        errorMessage
      ) : isEmpty ? (
        emptyMessage
      ) : (
        <>
          {data.map((aoi) => (
            <AoiListItem isGeotiffDataProvider={isGeotiffDataProvider} id={aoi.id} key={aoi.id} />
          ))}
        </>
      )}
    </div>
  );
}

export default React.memo(AoiList);
