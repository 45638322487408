export const LayerIds = {
  PROCESSED: "wm-processed-fill-extrusion",
  PROCESSED_OUTLINE: "wm-processed-outline",
  PROCESSED_POINTS: "point",
  PROCESSED_LINES: "wm-lines",
};

export const SourceIds = {
  PROCESSED: "wm-processed",
};

const polygonFilter = ["all", ["==", "$type", "Polygon"]];
const lineFilter = ["all", ["==", "$type", "LineString"]];
const symbolFilter = ["all", ["==", "$type", "Point"]];

export const LayerStyles = {
  getProcessed(id, source) {
    return {
      id: LayerIds.PROCESSED,
      type: "fill-extrusion",
      source,
      "source-layer": "vector_layer",
      minzoom: 9,
      filter: polygonFilter,
      paint: {
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          9,
          0,
          22,
          ["get", "building_height"],
        ],
        "fill-extrusion-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          9,
          1,
          22,
          0.25,
        ],
        "fill-extrusion-color": "#aaa000",
      },
    };
  },
  getProcessedOutline(id, source) {
    return {
      id: LayerIds.PROCESSED_OUTLINE,
      type: "line",
      source,
      "source-layer": "vector_layer",
      filter: polygonFilter,
      minzoom: 9,
      paint: {
        "line-color": "white",
        "line-width": ["interpolate", ["linear"], ["zoom"], 9, 0, 16, 2],
        "line-opacity": ["interpolate", ["linear"], ["zoom"], 9, 1, 18, 1],
      },
    };
  },

  getProcessedLines(id, source) {
    return {
      id: LayerIds.PROCESSED_LINES,
      type: "line",
      source,
      "source-layer": "vector_layer",
      filter: lineFilter,
      minzoom: 9,
      paint: {
        "line-color": "#aaa000",
        "line-width": ["interpolate", ["linear"], ["zoom"], 12, 0, 16, 10],
        "line-opacity": ["interpolate", ["linear"], ["zoom"], 12, 1, 18, 1],
      },
    };
  },

  getProcessedSympols(id, source) {
    return {
      id: "point",
      type: "circle",
      source,
      "source-layer": "vector_layer",
      filter: symbolFilter,
      minzoom: 9,
      paint: {
        "circle-radius": 5,
        "circle-color": "#aaa000",
      },
    };
  },
};
