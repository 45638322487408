import { MultiSelect2 } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { useState } from "react";
import { Trans } from "@lingui/react";

import {
  ProgressStatuses,
  STATUS_FAILED,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_UNPROCESSED,
} from "constants/common";

const elements = [
  STATUS_SUCCESS,
  STATUS_FAILED,
  STATUS_PENDING,
  STATUS_UNPROCESSED,
];

const translations = {
  завершен: "OK",
  ошибка: STATUS_FAILED,
  "в обработке": STATUS_PENDING,
  "не обработан": STATUS_UNPROCESSED,
};

export default function MultipleSelect() {
  const [items, setItems] = useState([]);
  const handleSelect = (el) => {
    setItems(
      !items.includes(translations[el.target.textContent])
        ? [...items, translations[el.target.textContent]]
        : items.filter((item) => item !== translations[el.target.textContent]),
    );
  };

  return (
    <div style={{ maxWidth: 400 }}>
      <MultiSelect2
        activeItem={items}
        items={elements}
        selectedItems={items}
        popoverProps={{ minimal: true }}
        itemRenderer={(val, itemProps) => {
          return (
            <MenuItem
              key={val}
              text={<Trans id={ProgressStatuses.T[val]} />}
              onClick={(elm) => handleSelect(elm)}
              active={itemProps.modifiers.active}
              selected={items.includes(val)}
            />
          );
        }}
        placeholder="Select statuses..."
        tagRenderer={(item) => <Trans id={ProgressStatuses.T[item]} />}
        onRemove={(item) => {
          setItems((items) => items.filter((elm) => elm !== item));
        }}
        onClear={() => setItems([])}
      />
    </div>
  );
}
