import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { gql, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import pathOr from "ramda/src/pathOr";
import {
  H2,
  Callout,
  Intent,
  Icon,
  Colors,
  Collapse,
  Button,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AoiList, AoiListActions } from "containers";
import { TextLoader, Subtitle } from "components";
import { POLL_INTERVAL } from "constants/envs";
import { Trans } from "@lingui/react";

export const GET_PROCESSING = gql`
  query getProcessing($processingId: ID!) {
    processing(id: $processingId) {
      name
      description
      blocks {
        name
        enabled
      }
      dataProvider {
        name
      }
      cost
      aoiCount
      bbox
      sourceType
      rasterLayer {
        id
        tileUrl
      }
      vectorLayer {
        tileJsonUrl
      }
      progress {
        status
        percentCompleted
        details {
          status
          area
        }
      }
      workflowDef {
        name
        description
      }
    }
  }
`;

function ProcessingSidebar({ openUploadAoiDialog }) {
  const { processingId } = useParams();

  const [showed, setShowed] = useState(true);

  const toggleShow = () => setShowed((prev) => !prev);

  const client = useApolloClient();
  const { data, status } = useQuery({
    queryKey: ["processing", processingId],
    queryFn: async () => {
      const result = await client.query({
        query: GET_PROCESSING,
        fetchPolicy: "no-cache",
        variables: { processingId },
      });
      return result?.data?.processing;
    },
    refetchInterval: POLL_INTERVAL,
  });

  const loading = status === "loading";

  const processingName = pathOr(null, ["name"])(data);
  const processingDesc = pathOr(null, ["description"])(data);
  const processingProgress = pathOr(null, ["progress"])(data);
  const aoiCount = pathOr(null, ["aoiCount"])(data);
  const workflowName = pathOr(null, ["workflowDef", "name"])(data);
  const workflowDesc = pathOr(null, ["workflowDef", "description"])(data);
  const blocks = pathOr(null, ["blocks"])(data);
  const dataProvider = pathOr(null, ["dataProvider"])(data);
  const isGeotiffDataProvider = pathOr(null, ["dataProvider", "name"])(data) === "GTIFF";
  const cost = pathOr(null, ["cost"])(data);
  const filteredBlocks = blocks?.filter((block) => block.enabled);
  return (
    <div className="processing-sidebar">
      <div className="processing-sidebar__header">
        <div className="processing-sidebar__header-top">
          <div className="processing-title">
            <H2>
              <TextLoader
                className="processing-title__name"
                length="25"
                text={processingName}
              />
            </H2>
            <Subtitle marginLeft="1" fontWeight="600">
              <TextLoader
                skip={!loading && !processingDesc}
                length="25"
                text={processingDesc}
              />
            </Subtitle>
          </div>
          <Button
            className="processing-sidebar__header-top__collapse-button"
            onClick={toggleShow}
            icon={!showed ? IconNames.ChevronDown : IconNames.ChevronUp}
            outlined
          />
        </div>

        <Collapse isOpen={showed}>
          <Callout
            intent={Intent.PRIMARY}
            icon={IconNames.APPLICATION}
            title={<TextLoader length="20" text={workflowName} />}
            style={{ marginTop: showed ? "20px" : "0" }}
          >
            <Subtitle marginLeft="1" fontWeight="600">
              <TextLoader
                ellipsize
                skip={!loading && !workflowDesc}
                length="15"
                text={workflowDesc}
              />
            </Subtitle>
          </Callout>

          {dataProvider && (
            <Callout
              intent={Intent.PRIMARY}
              icon={IconNames.GLOBE}
              className="processing-colout"
              title={<Trans id="Data Provider"/>} 
            >
              <Subtitle marginLeft="1" fontWeight="600">
                {dataProvider?.name}
              </Subtitle>
            </Callout>
          )}

          {filteredBlocks && filteredBlocks.length > 0 && (
            <Callout
              intent={Intent.PRIMARY}
              icon={IconNames.LIST}
              className="processing-colout"
              title={<Trans id="Options"/>} 
            >
              {filteredBlocks.map((block) => (
                <div className="processing-blocks__element" key={block.name}>
                  <Icon color={Colors.GREEN1} icon={"tick-circle"} />
                  <Subtitle marginLeft="1" marginRight="10" fontWeight="600">
                    {block.name}
                  </Subtitle>
                </div>
              ))}
            </Callout>
          )}

          <Callout
            intent={Intent.PRIMARY}
            icon={IconNames.DOLLAR}
            className="processing-colout"
            title={
              <TextLoader
                length="20"
                loading={loading && !cost}
                text={<>{<Trans id="Price"/>} ${cost}</> }
              />
            }
          ></Callout>
        </Collapse>
      </div>
      <div className="processing-sidebar__body">
        <AoiList isGeotiffDataProvider={isGeotiffDataProvider} aoiCount={aoiCount} onUpload={openUploadAoiDialog} />
        <AoiListActions
          progress={processingProgress}
          onUpload={openUploadAoiDialog}
          isGeotiffDataProvider={isGeotiffDataProvider}
        />
      </div>
    </div>
  );
}

export default React.memo(ProcessingSidebar);
