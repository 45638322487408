import React, { memo, useMemo } from "react";
import { areEqual } from "react-window";
import { Button, Popover } from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import { IconNames } from "@blueprintjs/icons";
import pathOr from "ramda/src/pathOr";

import { FormatArea, StatusTag } from "components";
import { Statuses } from "constants/common";
import { useTheme } from "hooks/use-theme";
import { ActionsMenu } from "./actions-menu";
import { LoadingItem } from "./loading-item";
import AoiMessagesDialog from "./aoi-messages-dialog";

let AoiListItem = function AoiListItem({ id, isGeotiffDataProvider }) {
  const { data, isLoading } = useQuery({
    queryKey: ["aoi", id],
    queryFn: () => null,
  });
  //], { variables: { id } });
  const area = pathOr(0, ["area"])(data);
  const percentCompleted = pathOr(0, ["progress", "percentCompleted"])(data);
  const aoiStatus = pathOr(Statuses.UNPROCESSED, ["progress", "status"])(data);
  const messages = pathOr(null, ["messages"])(data);
  const { themeClassName } = useTheme();

  const hasData = data != null;

  return useMemo(
    () =>
      isLoading || !hasData ? (
        <LoadingItem />
      ) : (
        <>
          <div className="aoi-list-item">
            <div className="aoi-item-coll area">
              <FormatArea cutZeros area={area} />
            </div>
            <div className="aoi-item-coll percent-completed">{`${percentCompleted} %`}</div>
            <div className="aoi-item-coll status">
              <StatusTag statusCode={aoiStatus} />
              <AoiMessagesDialog messages={messages} />
            </div>
            <div className="aoi-item-coll actions">
              <Popover
                className={themeClassName}
                content={<ActionsMenu isGeotiffDataProvider={isGeotiffDataProvider} aoiId={id} aoiStatus={aoiStatus} />}
              >
                <Button small minimal icon={IconNames.MORE} />
              </Popover>
            </div>
          </div>
        </>
      ),
    [
      id,
      area,
      percentCompleted,
      aoiStatus,
      themeClassName,
      isLoading,
      hasData,
      messages,
    ],
  );
};
AoiListItem = memo(AoiListItem, areEqual);

export { AoiListItem };
